import React, { useEffect, useState } from "react";

const CouponInput = ({
  handleChange,
  isLoading,
  handleApplyCoupon,
  couponValue,
  errorMessage,
  couponCode,
  couponPrice,
}) => {
  const [disable, setDisable] = useState(false);
  const [code, setCode] = useState();
  useEffect(() => {
    if (couponCode && couponCode.length > 0) {
      const couponCodes = couponCode.map((coupon) => coupon.code);
      setCode(couponCodes.join(","));
      const individualUse = couponCode.some((coupon) => coupon.isIndividualUse);

      if (individualUse) {
        setDisable(true);
      } else {
        setDisable(false);
      }
    }
  }, [couponCode]);
  return (
    <>
      <div className="w-full block">
        <div className="mt-6 w-full flex">
          <input
            className="w-full rounded-tl-md rounded-bl-md border border-gray-200 px-4 py-3 pl-5 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
            type="text"
            name="search"
            placeholder="Enter coupon code..."
            value={couponValue}
            onChange={(e) => handleChange(e.target.value)}
            // disabled={couponPrice && couponPrice !== null}
            disabled={disable}
          />
          <button
            className="max-w-[150px] w-full lg:max-w-[80px] lg:text-sm rounded-tr-md rounded-br-md bg-[#E9C95D] px-6 py-3 lg:px-2 font-medium text-white"
            type="button"
            onClick={handleApplyCoupon}
            // disabled={isLoading || (couponPrice && couponPrice !== null)}
            disabled={isLoading || disable}
          >
            {isLoading ? "Applying..." : "Apply"}
          </button>
        </div>
        {errorMessage ? (
          <p className="text-xs text-red-700">{errorMessage}</p>
        ) : couponCode && couponCode.length > 0 ? (
          <p className="text-xs ">
            Coupons used: <span className="text-green-700">{code}</span>
          </p>
        ) : (
          ""
        )}
      </div>
      {/* (couponCode && couponCode.length > 0 ? couponCode[couponCode.length -
      1].code : "") */}
    </>
  );
};

export default CouponInput;
