import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckoutProducts } from "../minor-components/CheckoutProducts";
import { CheckoutUserDetails } from "../minor-components/CheckoutUserDetails";
import { axiosInstance } from "../../constants/axiosInstance";
import { Loader } from "../minor-components/Loader";
import { useAlert } from "react-alert";
import { Footer } from "./Footer";
import { useAuth } from "../../authProvider";
import Noty from "noty";
import { getCoords } from "../utils";

export const Checkout = () => {
  const [loading, setLoading] = useState(false);
  const [cartItems, setCartItems] = useState([]);
  const [cartUser, setCartUser] = useState([]);
  const [subTotalPrice, setSubTotalPrice] = useState([]);
  const [totalPrice, setTotalPrice] = useState([]);
  const [totalProducts, setTotalProducts] = useState([]);
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [order, setOrder] = useState({});
  const [cartId, setCartId] = useState("");
  const [invoiceUrl, setInvoiceUrl] = useState("");
  const [isCouponUsed, setIsCouponUsed] = useState(false);
  const [couponPrice, setCouponPrice] = useState(0);
  const [couponCode, setCouponCode] = useState("");
  const [couponType, setCouponType] = useState("");

  let navigate = useNavigate();
  const alert = useAlert();
  const { setCartCount, cartCount } = useAuth();
  const [responseData, setResponseData] = useState();

  useEffect(() => {
    window.btcpay.onModalReceiveMessage(function (event) {
      event.preventDefault();
      if (event.data.status === "complete") {
        handleOrderPlacement();
      }
    });
  }, [order]);

  const fetchCart = async () => {
    setLoading(true);
    await axiosInstance
      .get("/api/v1/order/getcart", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        if (response.data.data !== null) {
          setLoading(false);
          setCartId(response.data.data._id);
          setIsCouponUsed(response.data.data.isCouponUsed);
          setCartItems(response.data.data?.details);
          setSubTotalPrice(response.data.data.subTotal);
          setTotalProducts(response.data.data.totalProducts);
          setTotalPrice(response.data.data.totalPrice);
          setCartUser(response.data.data?.userId);
          setCartCount(response.data.data.totalProducts);
          setCouponCode(response.data.data.couponCode);
          setCouponPrice(response.data.data.couponPrice);
          setResponseData(response.data.data);
          setCouponType(response.data.data.couponType);
        } else {
          setCartItems([]);
          setCartId("");
          setIsCouponUsed(false);

          setSubTotalPrice(0);
          setTotalProducts(0);
          setTotalPrice(0);
          setCartUser([]);
          setCartCount(0);
          setCouponCode("");
          setCouponPrice(0);
          setResponseData([]);
        }
        setResponseData(response.data.data);
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (localStorage.getItem("token")) {
      fetchCart();
      setLoading(false);
      navigate("/checkout");
    } else {
      navigate("/login");
    }
  }, [loading]);

  const handleDelivery = async (day) => {};

  const couponData = localStorage.getItem("couponCode");

  const handleSubmit = async (
    deliveryDay,
    total,
    deliveryFee,
    address,
    postalCode,
    paymentMethod,
    coordinates
  ) => {
    // Validate payment method selection
    if (paymentMethod === "") {
      alert.show("Please Select Payment Method");
      return;
    }
    const coords = await getCoords(coordinates);

    // Create the order object
    const order = {
      details: cartItems,
      totalPrice,
      deliveryFee,
      deliveryType: deliveryDay,
      subTotal: subTotalPrice,
      totalProducts: totalProducts,
      formattedAddress: address,
      postalCode: postalCode,
      paymentMethod: paymentMethod,
      // geometry: [coordinates[0], coordinates[1]],
      geometry: [coords[0], coords[1]],
      user: cartUser,
      // couponCode: couponData && couponData !== null ? couponData : "",
      couponCode,
      referralPoints: credentials.points,
      referrerId: credentials.referrerId,
    };

    // Start processing
    setLoading(true);
    const user = {
      userName: cartUser.userName,
      chatId: cartUser.chatId,
      _id: cartUser._id,
    };
    try {
      if (paymentMethod === "Cryptocurrency") {
        setOrder(order);
        handleCryptoPayment(total, user, order);
      } else {
        const response = await axiosInstance.post(
          "/api/v1/order/placeorder",
          order,
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );

        if (response.data.success) {
          localStorage.removeItem("couponCode");
          localStorage.removeItem("couponPrice");
          // If order is successfully placed, proceed to clear the cart

          // fetchCart();
          const cartResponse = await axiosInstance.delete(
            "/api/v1/order/deletecart",
            {
              headers: {
                Authorization: localStorage.getItem("token"),
              },
            }
          );

          if (cartResponse.data.success) {
            alert.show("Order Placed Successfully");
            setCartCount(0);
            navigate("/order/" + response.data.data);
          } else {
            alert.show("Something Went Wrong with clearing the cart");
          }
        } else {
          alert.show(response.data.message || "Failed to place the order");
        }
      }
    } catch (error) {
      console.error("Error during order placement:", error);
      alert.show(error.response.data.message);
    } finally {
      setLoading(false);
    }
  };

  const handleCryptoPayment = async (totalPrice, user, order) => {
    try {
      const response = await axiosInstance.post(
        `/api/v1/order/createinvoice`,
        {
          totalPrice: totalPrice,
          currency: "EUR",
          user: user,
          order: order,
        },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.data;
      if (data.success) {
        setInvoiceUrl(data.data.url);

        const invoiceId = data.data.id;
        window.btcpay.showInvoice(invoiceId);
      } else {
        console.error("Failed to create payment invoice:", data.message);
      }
    } catch (error) {
      console.error("Error in payment API:", error);
    }
  };

  const handleOrderPlacement = async () => {
    try {
      if (!order || !order.details.length) {
        console.log("No order data available to place the order");
        return;
      }
      const response = await axiosInstance.post(
        "/api/v1/order/placeorder",
        order,
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      if (response.data.success) {
        localStorage.removeItem("couponCode");
        localStorage.removeItem("couponPrice");
        const cartResponse = await axiosInstance.delete(
          "/api/v1/order/deletecart",
          {
            headers: {
              Authorization: localStorage.getItem("token"),
            },
          }
        );
        if (cartResponse.data.success) {
          alert.show("Order Placed Successfully");
          setCartItems([]);
          setTotalPrice(0);
          setSubTotalPrice(0);
          setTotalProducts(0);

          navigate(`/order/${response.data.data}`);
        } else {
          alert.show("Something Went Wrong with clearing the cart");
        }
      } else {
        alert.show(response.data.message || "Failed to place the order");
      }
    } catch (error) {
      console.error("Error placing order:", error);
      alert.show("An error occurred while placing the order.");
    } finally {
      setLoading(false);
    }
  };

  const handleItem = async (item, action) => {
    setLoading(true);
    const details = {
      productId: item.id,
      quantity: item.quantity,
      price: item.price,
      variationName: item.variationName,
    };
    let url = "";

    if (action === "p") {
      url = "/api/v1/order/addtocart";
    } else if (action === "m") {
      url = "/api/v1/order/decreasecartquantity";
    }

    axiosInstance
      .post(url, details, {
        headers: {
          "Content-Type": "application/json",
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((res) => {
        setLoading(false);
        if (action === "m") {
          fetchCart();
        }
      })
      .catch((err) => {
        setLoading(false);
      });
  };
  
  const [credentials, setcredentials] = useState({
    userName: "",
    chatId: "",
    formattedAddress: "",
    referralCode: "",
    role: "",
    points: "",
    referrerId: "",
    userId: "",
  });

  const fetchUser = async () => {
    await axiosInstance
      .get("/api/v1/user/getsingleuser", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        setcredentials({
          userName: response.data.data.userName,
          chatId: response.data.data.chatId,
          formattedAddress: response.data.data.formattedAddress,
          referralCode: response.data.data.referralCode,
          role: response.data.data.role,
          points: response.data.data.referralPoints,
          referrerId: response.data.data.referrerId,
          userId: response.data.data._id,
        });
      })
      .catch((err) => {
        new Noty({
          type: "error",
          timeout: 2000,
          text: "Something Went Wrong",
        }).show();
      });
  };

  useEffect(() => {
    fetchUser();
  }, []);

  return (
    <>
      {!loading ? (
        <>
          <div className="bg-neutral-50 min-h-[calc[(100vh_-_5rem)]">
            {cartItems.length > 0 || !cartItems ? (
              <>
                <div className="grid sm:px-10 lg:grid-cols-2 lg:px-20 xl:px-32 ">
                  <div className="px-4 pt-8">
                    <p className="text-xl font-medium ">Order Summary</p>
                    {cartItems.map((product, index) => {
                      let cartPrice;
                      if (
                        product.productVariations &&
                        product.productVariations.length > 0
                      ) {
                        cartPrice = product.productVariations.reduce(
                          (initial, acc) => {
                            return initial + acc.price * acc.quantity;
                          },
                          0
                        );
                      } else {
                        cartPrice =
                          credentials.role === "customer" ||
                          credentials.role === ""
                            ? product?.productId?.price
                            : product?.productId?.rolePrices &&
                              product?.productId?.rolePrices[
                                `${credentials.role}`
                              ]
                            ? product?.productId?.rolePrices &&
                              product?.productId?.rolePrices[
                                `${credentials.role}`
                              ]
                            : product?.productId?.price;
                      }

                      return (
                        <div
                          className="mt-8 space-y-3 rounded-lg border bg-white px-2 py-4 sm:px-6 "
                          key={index}
                        >
                          <CheckoutProducts
                            cartProductId={product?.productId?._id}
                            cartProductName={product?.productId?.name}
                            cartProductImage={product?.productId?.productPhoto}
                            cartProductPrice={cartPrice}
                            cartProductBrand={product?.productId?.brand}
                            cartProductCategory={product?.productId?.category}
                            cartProductDescription={
                              product?.productId?.description
                            }
                            cartProductType={product?.productId?.type}
                            cartProductQuantity={product?.quantity}
                            cartProductUnits={product?.productId?.units}
                            getItem={handleItem}
                            fetchCart={fetchCart}
                            productVariations={product.productVariations}
                          />
                        </div>
                      );
                    })}
                  </div>
                  <div className="mt-10  px-4 pt-8 lg:mt-0">
                    <CheckoutUserDetails
                      handleSubmit={handleSubmit}
                      handleDeliveryDay={handleDelivery}
                      cartUser={cartUser}
                      totalPrice={totalPrice}
                      subTotalPrice={subTotalPrice}
                      deliveryFee={deliveryFee}
                      setDeliveryFee={setDeliveryFee}
                      handleOrderPlacement={handleOrderPlacement}
                      fetchCart={fetchCart}
                      cartId={cartId}
                      isCouponUsed={isCouponUsed}
                      points={credentials.points}
                      chatId={credentials.chatId}
                      setTotalPrice={setTotalPrice}
                      couponCode={couponCode}
                      couponPrice={couponPrice}
                      couponType={couponType}
                      userId={credentials.userId}
                      userName={credentials.userName}
                    />
                  </div>
                </div>
              </>
            ) : (
              <>
                <div className="block text-center justify-center my-10 min-h-[calc(100vh_-_11.5rem)]">
                  <lottie-player
                    src="https://assets1.lottiefiles.com/packages/lf20_qh5z2fdq.json"
                    background="transparent"
                    speed={1}
                    style={{ height: "300px", display: "inline-block" }}
                    loop
                    autoPlay
                  />
                  <p className="text-4xl text-black font-bold">
                    Cart is Empty! Please Add some Products
                  </p>
                </div>
              </>
            )}
          </div>
          <div>
            <Footer />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};
