import React from "react";
import { Navigate, useLocation } from "react-router-dom";

function withTokenRequired(Component) {
  return function WrappedComponent(props) {
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    if (!token) {
      return <Navigate to="/" />;
    }

    return <Component {...props} />;
  };
}

// if not token, route to login else route to component that you are trying to visit(private page)

export default withTokenRequired;
