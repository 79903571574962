/* eslint-disable no-unused-vars */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useAlert } from "react-alert";
import { axiosInstance } from "../../constants/axiosInstance";
import { baseURL } from "../../constants/baseURL";

import { Loader } from "./Loader";

import { useAuth } from "../../authProvider";

export const SingleProductDetails = ({ item }) => {
  const { setCartCount } = useAuth();
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [checkboxInput, setCheckboxInput] = useState({});
  const cartPrice =
    item.role === "customer" || item.role === ""
      ? item.price
      : item.rolePrices && item.rolePrices[`${item.role}`]
      ? item.rolePrices && item.rolePrices[`${item.role}`]
      : item.price;
  // const [price, setPrice] = useState(item?.price || 0);
  const [price, setPrice] = useState(cartPrice || 0);
  const alert = useAlert();
  const navigate = useNavigate();

  const handleChecked = (e) => {
    const name = e.target.name;
    setCheckboxInput((prev) => ({ ...prev, [name]: e.target.checked }));
  };

  const addToCart = async () => {
    if (!localStorage.getItem("token")) {
      alert.show("Please sign in to add items to your cart.");
      navigate("/login");
      return;
    }

    const selectedVariations =
      item.variations
        ?.filter((variation) => checkboxInput[variation.name] === true)
        .map((variation) => {
          variation.quantity = 1;
          return {
            ...variation,
            isChecked: true,
          };
        }) || [];

    const variationPrice = selectedVariations.reduce((initial, acc) => {
      return Number(initial) + Number(acc.price);
    }, 0);

    const variationName = selectedVariations.map((variation) =>
      variation.name.toLowerCase()
    );

    const newItem = variationPrice || item.price;

    const details = {
      productId: item.id,
      name: item.name,
      price: variationPrice || cartPrice,
      brand: item.brand,
      productPhoto: item.productPhoto,
      type: item.type,
      description: item.description,
      category: item.category,
      quantity: 1,
      variations: selectedVariations,
      variationName,
    };
    try {
      const response = await axiosInstance.post(
        "/api/v1/order/addtocart",
        details,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: localStorage.getItem("token"),
          },
        }
      );

      setCartCount(response.data.data.totalProducts);
      alert.show("Product Added to Cart");
      // window.location.reload();
    } catch (err) {
      alert.show("Failed to add product to cart. Please try again.");
    }
  };

  useEffect(() => {
    const imageURL =
      Array.isArray(item.productPhoto) &&
      item.productPhoto.map((photo) => {
        return photo.startsWith(baseURL) ? photo : `${photo}`;
      });

    setImageUrl(imageURL[0]);
  }, [item.productPhoto]);

  const handleSetImage = (url) => {
    const imageURL = url.startsWith(baseURL) ? url : `${url}`;

    setImageUrl(imageURL);
  };

  if (loading) return <Loader />;

  return (
    <div className="w-full h-auto flex text-[#4A4A4A] bg-neutral-50 mt-4">
      <div className="w-full grid grid-cols-1 overflow-x-auto">
        <div className="p-6">
          <div className="w-full relative">
            {imageUrl.endsWith(".mp4") ? (
              <video
                className="rounded-t-lg w-full h-60 object-cover object-center bg-slate-200"
                controls
                alt="product media"
              >
                <source src={imageUrl} type="video/mp4"></source>
              </video>
            ) : (
              <img
                src={imageUrl}
                alt="Product"
                className="rounded-t-lg w-full h-60 object-cover object-center bg-slate-200"
              />
            )}

            <div className="absolute right-1 top-1 flex gap-1">
              {Array.isArray(item.productPhoto) &&
                item.productPhoto.map((src, i) => {
                  return (
                    <div
                      className="w-[30px] h-[30px] overflow-hidden border-2 border-white cursor-pointer"
                      key={i}
                      onClick={() => handleSetImage(src)}
                    >
                      {src.endsWith(".mp4") ? (
                        <video
                          className="w-full h-full object-cover"
                          controls={false}
                          alt="product media"
                        >
                          <source src={src} type="video/mp4"></source>
                        </video>
                      ) : (
                        <img
                          src={src}
                          className="w-full h-full object-cover"
                          alt="productPhoto"
                        />
                      )}
                    </div>
                  );
                })}
            </div>
          </div>

          <div className="shadow rounded-b-lg p-4 bg-white flex gap-2">
            <div className="w-full">
              <h1 className="text-xl font-bold">
                {item.name || "Product Name"}
              </h1>
              <p className="text-sm text-gray-500">Category: {item.type || "Product Type"}</p>
              <p className="text-sm text-gray-500 ">
                Description: {item.description || "No description provided."}
              </p>
              <p className="text-sm text-gray-500 ">
                Stock available: {item.units}
              </p>
              <p className="text-sm font-semibold">Price: €{price}</p>
            </div>

            {item.variations && item.variations.length > 0 && (
              <article className="w-full ">
                <h3 className="text-base font-bold capitalize">variations</h3>

                <ul className="w-full flex gap-3 flex-col h-[100px] overflow-y-auto">
                  {item.variations.map((item, i) => {
                    return (
                      <li key={i} className="w-full flex justify-between gap-1">
                        <span className="text-sm flex flex-col gap-1">
                          <span className=" text-sm capitalize font-medium">
                            {item.name}
                          </span>
                          <span className=" text-sm capitalize ">
                            Price: €{item.price}
                          </span>
                        </span>
                        <input
                          type="checkbox"
                          name={`${item.name}`}
                          className="text-sm"
                          onChange={handleChecked}
                        />
                      </li>
                    );
                  })}
                </ul>
              </article>
            )}
          </div>
          <button
            className="mt-4 w-full bg-[#E9C95D] text-black p-2 rounded hover:bg-[#D9BA5C] transition"
            onClick={addToCart}
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};
