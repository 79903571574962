import React from "react";

const PointsInput = ({
  handleApplyPoints,
  isLoading,
  referralPoints,
  handleChangePointsValue,
}) => {
  return (
    <>
      <div className="mt-6 w-full flex">
        <input
          className="w-full rounded-tl-md rounded-bl-md border border-gray-200 px-4 py-3 pl-5 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
          type="number"
          name="search"
          placeholder="Enter points"
          value={referralPoints}
          onChange={(e) => handleChangePointsValue(e.target.value)}
          // disabled={couponPrice && couponPrice !== null}
        />
        <button
          className=" max-w-[150px] w-full lg:max-w-[90px] lg:text-sm rounded-tr-md rounded-br-md bg-[#E9C95D] px-6 py-3 lg:px-2 font-medium text-white"
          type="submit"
          onClick={handleApplyPoints}
          disabled={isLoading}
        >
          {isLoading ? "Applying..." : "Use Points"}
        </button>
      </div>
    </>
  );
};

export default PointsInput;
