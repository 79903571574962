import classes from "./Layout.module.css";
import { Navbar } from "../major-components/Navbar";
import { useLocation } from "react-router-dom";
import React from "react";

function Layout(props) {
  const { pathname } = useLocation();

  const token = localStorage.getItem("token");

  return (
    <>
      {!token && pathname === "/" ? (
        <section className=" flex min-h-screen bg-gray-100 justify-center items-center">
          <div className="w-[90%] mx-auto max-w-[400px] bg-white rounded-lg shadow-2xl p-5">
            <article className="w-full">
              <h2 className="font-bold text-2xl">Oops!</h2>

              <p className="text-base my-3 leading-normal">
                Please contact the admin for access.
              </p>
            </article>
          </div>
        </section>
      ) : (
        <div>
          <Navbar />
          <main className={classes.main}>{props.children}</main>
        </div>
      )}
    </>
  );
}

export default Layout;
