import React, { useEffect, useRef } from 'react';

const TelegramLogin = ({ botName }) => {
  const telegramLoginRef = useRef(null);
  useEffect(() => {
    if (telegramLoginRef.current) {
      // Clear previous widget instance
      telegramLoginRef.current.innerHTML = "";

      const script = document.createElement('script');
      script.src = "https://telegram.org/js/telegram-widget.js?22";
      script.setAttribute('data-telegram-login', botName);
      script.setAttribute('data-size', 'large');
      script.setAttribute('data-radius', '10');
      script.setAttribute('data-onauth', 'onTelegramAuth(user)');
      script.setAttribute('data-request-access', 'write');
      script.async = true;

      telegramLoginRef.current.appendChild(script);
    }
  }, [botName]); 

  return (
    <button
    ref={telegramLoginRef}
    type="submit"
    className="mt-2 group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-secondaryText bg-sky-500 hover:bg-myBg-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-myBg-500"
  >
    Login
  </button>
  );
};

export default TelegramLogin;