import { Link } from "react-router-dom";
import NavLogo from "../../assets/logo.png";

export const Footer = () => {
  return (
    <div className="h-auto bg-[#3A3A3A]">
      <div className="text-gray-300 text-center py-4">
        © 2023 Bud Bros. All rights reserved.
      </div>
      <script src="https://www.gstatic.com/firebase/6.2.0/firebase.js"></script>
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/firebase/9.9.2/firebase-auth.min.js"
        integrity="sha512-VFdqT83o0epWSKSZOGryhioVcx0sCaPbujRJ76odePTjA/Mqcwwp3Zm5jUYfi7WjRPROpAYmOzXXSEGUAtKviA=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      ></script>
      <script
        src="https://cdnjs.cloudflare.com/ajax/libs/firebase/9.9.2/firebase-app.min.js"
        integrity="sha512-aCu7Uq/UKhm7gcvqNmT2VcPzNTjRy5cSsyNw1EaBfLvso4CFmqvpm8Pnz+w20lsNv0rjzYwtmNqmghYuDa5k2Q=="
        crossOrigin="anonymous"
        referrerPolicy="no-referrer"
      ></script>
    </div>
  );
};
