export const extractAddress = (place) => {
  if (!place || !place.address_components) {
    console.error("Invalid place object provided:", place);
    return {
      sublocal2: "",
      sublocal: "",
      city: "",
      state: "",
      zip: "",
      country: "",
      plain() {
        return "";
      },
    };
  }

  const address = {
    sublocal2: "",
    sublocal: "",
    city: "",
    state: "",
    zip: "",
    country: "",
    plain() {
      const sublocal2Part = this.sublocal2 ? this.sublocal2 + ", " : "";
      const sublocalPart = this.sublocal ? this.sublocal + ", " : "";
      const cityPart = this.city ? this.city + ", " : "";
      const zipPart = this.zip ? " " + this.zip + ", " : "";
      const statePart = this.state ? this.state + ", " : "";
      const countryPart = this.country ? this.country : "";
      return `${sublocal2Part}${sublocalPart}${cityPart}${zipPart}${statePart}${countryPart}`.trim();
    },
  };

  place.address_components.forEach((component) => {
    const { types, long_name } = component;
    if (types.includes("sublocality_level_2")) address.sublocal2 = long_name;
    if (types.includes("sublocality_level_1")) address.sublocal = long_name;
    if (types.includes("locality")) address.city = long_name;
    if (types.includes("administrative_area_level_1"))
      address.state = long_name;
    if (types.includes("postal_code")) address.zip = long_name;
    if (types.includes("country")) address.country = long_name;
  });

  return address;
};

export function loadAsyncScript(src) {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    Object.assign(script, {
      type: "text/javascript",
      async: true,
      src,
    });
    script.addEventListener("load", () => resolve(script));
    script.onload = () => {
      console.log("Google Maps script loaded successfully.");
      // resolve();
    };
    document.head.appendChild(script);
  });
}

export const getCoords = (coordinates) => {
  return new Promise((resolve, reject) => {
    if (coordinates[0] === "" && coordinates[1] === "") {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            resolve([position.coords.latitude, position.coords.longitude]);
          },
          (error) => reject(error)
        );
      } else {
        reject(new Error("Geolocation is not supported"));
      }
    } else {
      resolve(coordinates);
    }
  });
};
