import React, { useEffect, useState } from 'react'
import { Footer } from "./Footer";
import { axiosInstance } from "../../constants/axiosInstance";
import { Loader } from '../minor-components/Loader';

export const Delivery = () => {

  const [loading, setLoading] = useState(false)
  const [data, getData] = useState('');
  const [headings, getHeading] = useState('');

  useEffect(() => {
    getDelivery();
  }, []);


  const getDelivery = async () => {
    setLoading(true)
    await axiosInstance.get('/api/v1/delivery/getdelivery')
      .then((res) => {
        let aboutUs = res.data.data;
        if (aboutUs.length !== 0) {
          getData(aboutUs[0]?.data)
          getHeading(aboutUs[0]?.heading)
        }
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      })
  }

  return (
    <>
      {!loading ? (
        <>
          <div className="py-20 text-center">

            <div className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4" dangerouslySetInnerHTML={{ __html: headings !== '' ? headings : 'Delivery Zone' }}></div>
            <div className="w-[100%] h-[60vh]  bg-no-repeat bg-center  bg-cover  flex justify-center  items-center gap-2 my-4">
              {/* <img src={mailDelivery} className='w-[100%] h-[50vh] object-cover' /> */}
              <iframe title='deliveryIframe' src="https://www.google.com/maps/d/embed?mid=1b6tQRhba1aH_9eMwG5d_jkR6xgXKCYE&ehbc=2E312F" className="w-[70%] h-[60vh]"></iframe>
            </div>

            <div className="lg:px-28 px-5 text-left" dangerouslySetInnerHTML={{ __html: data !== '' ? data : '' }}></div>
          </div>
          <div>
            <Footer />
          </div>
        </>
      ) : (
        <Loader />
      )}
    </>
  );
};

