import React, { useEffect } from "react";
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import { HeroSection } from "./components/major-components/HeroSection";
import { SignIn } from "./components/major-components/SignIn";
import { SignUp } from "./components/major-components/SignUp";
import { Agevalidation } from "./components/major-components/AgeConformation";
import { ResetPassword } from "./components/major-components/ForgotPassword";
import { ConformPassword } from "./components/major-components/ResetPassword";
import { AllProducts } from "./components/major-components/AllProducts";
import { SingleProduct } from "./components/major-components/SingleProduct";
import { Checkout } from "./components/major-components/Checkout";
import { Account } from "./components/major-components/Account";
import { Blogs } from "./components/major-components/Blogs";
import { OrderDetail } from "./components/major-components/OrderDetail";
import { AboutUs } from "./components/major-components/AboutUs";
import { FAQPage } from "./components/major-components/Faq";
import { Delivery } from "./components/major-components/Delivery";
import Layout from "./components/layout/Layout";
//--------------Noty CSS----------------------
import "./assets/sass/app.scss";
import "./components/fontawesomeIcons";
import { BlogDetails } from "./components/minor-components/BlogDetails";
import { Notifications } from "./components/major-components/Notification";
import withTokenRequired from "./withTokenRequired";
import ReactGA from "react-ga4";

export function App() {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (token && pathname === "/") {
      navigate("/all-products");
    }
  }, [pathname, token]);

  useEffect(() => {
    ReactGA.initialize("G-847M7WCRX6");
  }, []);
  return (
    <div>
      <Layout>
        <Routes>
          <Route path="/login" element={withTokenRequired(SignIn)()} />
          <Route path="/sign-up" element={withTokenRequired(SignUp)()} />
          <Route path="/all-products" element={<AllProducts />} />
          <Route path="/checkout" element={<Checkout />} />
          <Route path="/brand/:brandName" element={<SingleProduct />} />
          <Route path="/order/:orderid" element={<OrderDetail />} />
          <Route path="/age-verification" element={<Agevalidation />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/blog" element={<Blogs />} />
          <Route path="/blog/:id" element={<BlogDetails />} />
          <Route path="/accounts" element={<Account />} />
          <Route path="/reset-password/:token" element={<ConformPassword />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/notifications" element={<Notifications />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/faq" element={<FAQPage />} />
        </Routes>
      </Layout>
    </div>
  );
}
