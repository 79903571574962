import React, { useEffect, useState } from 'react'
import { Footer } from "./Footer";
import { axiosInstance } from "../../constants/axiosInstance";
import { Loader } from '../minor-components/Loader';

export const AboutUs = () => {

    const [loading, setLoading] = useState(false)
    const [data, getData] = useState('');
    const [headings, getHeading] = useState('');

    useEffect(() => {
        getAboutUs();
    }, []);


    const getAboutUs = async () => {
        setLoading(true)
        await axiosInstance.get('/api/v1/about/getaboutus')
            .then((res) => {
                let aboutUs = res.data.data;
                if (aboutUs.length !== 0) {
                    setLoading(false)
                    getData(aboutUs[0].data)
                    getHeading(aboutUs[0].heading)
                }
                setLoading(false)
            })
            .catch((err) => {
                setLoading(false)
            })
    }

    return (
        <>
            {!loading ? (
                <>
                    <div className="2xl:container 2xl:mx-auto lg:py-16 lg:px-20 md:py-12 md:px-6 py-9 px-4 text-center" >
                        <div className="text-3xl lg:text-4xl font-bold leading-9 text-gray-800 pb-4" dangerouslySetInnerHTML={{ __html: headings !== '' ? headings : 'About Us' }}></div>
                        <div className="block text-center justify-center my-10">
                            <lottie-player src="https://assets7.lottiefiles.com/packages/lf20_nvhJxJ38ZY.json" background="transparent" speed={1} style={{ height: '600px', display: 'inline-block' }} loop autoplay />
                        </div>
                        <div className="lg:px-28 px-5 text-left" dangerouslySetInnerHTML={{ __html: data !== '' ? data : '' }}></div>
                    </div>
                    <div>
                        <Footer />
                    </div>
                </>
            ) : (
                <Loader />
            )}
        </>
    );
};

