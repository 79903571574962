import { createContext, useState, useEffect, useContext } from "react";
import { useAlert } from "react-alert";
import { useNavigate } from "react-router-dom";
import { axiosInstance } from "./constants/axiosInstance";
import { baseURL } from "./constants/baseURL";

const AuthContext = createContext();

// Helper function to decode the JWT and check expiration
const isTokenExpired = (token) => {
  if (!token) return false; // No token means not expired in terms of needing to logout, just not logged in

  try {
    const payloadBase64 = token.split(".")[1];
    const decodedJson = atob(payloadBase64);
    const decoded = JSON.parse(decodedJson);
    const exp = decoded.exp * 1000; // Convert to milliseconds

    return Date.now() >= exp;
  } catch (error) {
    console.error("Failed to decode token:", error);
    return false; // Assume the token is not expired if there's an error decoding it
  }
};

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [cartCount, setCartCount] = useState(0);
  const navigate = useNavigate();
  const alert = useAlert();
  const [searchInput, setSearchInput] = useState("");
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [allProducts, setAllProducts] = useState([]);

  const fetchCart = async () => {
    await axiosInstance
      .get("/api/v1/order/getcart", {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      })
      .then((response) => {
        const { data } = response.data;
        // console.log({ response });
        if (data) {
          setCartCount(data.totalProducts);
        } else {
          setCartCount(0);
        }
      })
      .catch((err) => {
        // setLoading(false);
      });
  };

  useEffect(() => {
    fetchCart();
  }, [cartCount]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && isTokenExpired(token)) {
      alert.show("Your session has expired. Please log in again.");
      logout();
    } else if (token) {
      // console.log("FAREWELL");
      setIsAuthenticated(true);
    }
  }, []);

  const login = (token) => {
    localStorage.setItem("token", token);
    // console.log("Login with token:", token);
    setIsAuthenticated(true);
    navigate("/all-products");
  };

  const logout = () => {
    localStorage.removeItem("token");
    setIsAuthenticated(false);
    navigate("/login");
  };

  const fetchProducts = async () => {
    const data = await fetch(`${baseURL}/api/v1/product/getproducts`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    });
    const jsonData = await data.json();

    setAllProducts(jsonData.data);
  };

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token && !isTokenExpired(token)) {
      fetchProducts();
    }
  }, []);
  // console.log({ authCount: cartCount });
  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        login,
        logout,
        cartCount,
        setCartCount,
        searchInput,
        setSearchInput,
        filteredProducts,
        setFilteredProducts,
        allProducts,
        setAllProducts,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);

export default AuthProvider;
