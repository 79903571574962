import { useEffect, useRef, useState } from "react";
import { ShippingMethods } from "./CheckoutShippingMethod";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLatLng } from "use-places-autocomplete";
import { PiUserList, PiUserRectangleLight } from "react-icons/pi";
import SameDayDelivery from "../../assets/Samedaydelivery.png";
import { Link } from "react-router-dom";
import CouponInput from "./CouponInput";
import { axiosInstance } from "../../constants/axiosInstance";
import { extractAddress, loadAsyncScript } from "../utils";
import PointsInput from "./PointsInput";

const apiKey = process.env.REACT_APP_GMAP_API_KEY;
const mapApiJs = process.env.REACT_APP_MAP_API_JS;
const geocodeJson = process.env.REACT_APP_GEOCODE_JSON;

export const CheckoutUserDetails = (props) => {
  // console.log(props.totalPrice, 'total price');
  let lats, lngs;
  let formatted_address;
  const [selected, setSelected] = useState("");
  const [placeOrder, setPlaceOrder] = useState(false);
  // const [totalPrice, setTotalPrice] = useState(
  //   props.subTotalPrice + props.deliveryFee
  // );
  // const totalPrice = props.totalPrice;
  const [paymentMethod, setPaymentMethod] = useState("");
  const [credentials, setcredentials] = useState({
    postalCode: "",
  });
  const searchInput = useRef(null);
  const [address, setAddress] = useState(
    props.cartUser?.formattedAddress || ""
  );

  const [isLoading, setIsLoading] = useState(false);
  const [couponValue, setCouponValue] = useState("");
  const [isApplying, setIsApplying] = useState(false);
  const [errormessage, setErrormessage] = useState("");
  const [zonesData, setZonesData] = useState({});
  const [freeRegions, setFreeRegions] = useState([]);
  const [placeCoords, setPlaceCoords] = useState({
    lat: "",
    lng: "",
  });
  const [applyingPoints, setApplyingPoints] = useState(false);
  const [referralPoints, setReferralPoints] = useState("");

  const handleChangeCouponValue = (value) => {
    setCouponValue(value);
  };

  const handleChangePointsValue = (value) => {
    setReferralPoints(value);
  };

  
  useEffect(() => {
    if (selected === "ASAP Long Distance Delivery") {
      props.setDeliveryFee(10);
    } else if (selected === "Long Distance Delivery") {
      props.setDeliveryFee(5);
    }
  }, [selected]);
  
  useEffect(() => {
    props.setTotalPrice(props.subTotalPrice + props.deliveryFee);
    // setTotalPrice(props.subTotalPrice + props.deliveryFee);
  }, [props.subTotalPrice, props.deliveryFee]);

  // init gmap script
  const initMapScript = () => {
    if (document.querySelector('script[src*="maps.googleapis.com"]')) {
      return Promise.resolve();
    }
    // if (window.google && window.google.maps) {
    //   return Promise.resolve();
    // }
    const src = `${mapApiJs}?key=${apiKey}&libraries=places&v=weekly`;
    return loadAsyncScript(src);
  };

  // do something on address change
  const onChangeAddress = async (autocomplete) => {
    const place = autocomplete.getPlace();
    if (place.formatted_address) {
      setAddress(place.formatted_address);
    }
  };

  const initAutocomplete = () => {
    if (
      !searchInput.current ||
      !window.google ||
      !window.google.maps ||
      !window.google.maps.places
    ) {
      console.error("Google Maps API not loaded");
      return;
    }

    const autocomplete = new window.google.maps.places.Autocomplete(
      searchInput.current
    );
    autocomplete.setFields([
      "address_component",
      "formatted_address",
      "geometry",
    ]);
    autocomplete.addListener("place_changed", () =>
      onChangeAddress(autocomplete)
    );
  };

  const reverseGeocode = async ({ latitude: lat, longitude: lng }) => {
    const url = `${geocodeJson}?key=${apiKey}&latlng=${lat},${lng}`;
    searchInput.current.value = "Getting your location...";
    fetch(url)
      .then((response) => {
        return response.json();
      })
      .then((location) => {
        const place = location.results[0];
        const _address = extractAddress(place);

        // Set latitude and longitude
        lats = place.geometry.location.lat;
        lngs = place.geometry.location.lng;

        setPlaceCoords({
          lat: place.geometry.location.lat,
          lng: place.geometry.location.lng,
        });
        setcredentials((prevState) => ({
          ...prevState,
          postalCode: _address.zip,
        }));
        searchInput.current.value = _address.plain();
        setAddress(searchInput.current.value);
      })
      .catch((error) => {
        console.error("Error fetching location:", error);
      });
  };

  const findMyLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        reverseGeocode(position.coords);
      });
    }
  };

  useEffect(() => {
    initMapScript()
      .then(() => initAutocomplete())
      .catch(() => {
        console.error("Google Maps script failed to load");
      });
  }, []);

  const changePlaceOrder = (val) => {
    setPlaceOrder(true);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log(placeOrder);

    if (!placeOrder) {
      return;
    }
    let deliveryFee = selected === "ASAP Long Distance Delivery" ? 10 : 5;

    const freeShippingExists = props.couponCode.some(
      (coupon) => coupon.type === "Free Shipping"
    );

    if (
      freeRegions.includes(address && address.toLowerCase()) ||
      freeShippingExists
    ) {
      deliveryFee = 0;
    }
    // const address = formatted_address;
    const postalCode = document.getElementById("postal-code")?.value;

    // Check if latitude and longitude are set
    const coordinates = [placeCoords.lat, placeCoords.lng];
    if (coordinates.includes(null) || coordinates.includes(undefined)) {
      console.error(
        "Invalid coordinates: latitude or longitude is null or undefined."
      );
      return;
    }

    try {
      setIsLoading(true);
      props.handleSubmit(
        selected,
        props.totalPrice,
        deliveryFee,
        address,
        postalCode,
        paymentMethod,
        coordinates
      );
    } catch (error) {
      console.error("Error in payment API:", error);
    } finally {
      setIsLoading(false);
    }
  };

  function changeCheck(val) {
    setSelected(val);
    setPlaceOrder(true);
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setcredentials((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handlePaymentOptions = (e) => {
    setPaymentMethod(e.target.value);
  };

  const handleApplyCoupon = async () => {
    try {
      setIsApplying(true);
      const response = await axiosInstance.post("/api/v1/coupon/applyCoupon", {
        couponCode: couponValue,
        cartId: props.cartId,
        cartPrice: props.totalPrice,
        chatId: props.chatId,
        userId: props.userId,
        fullName: props.userName,
      });

      if (response.data.success) {
        setIsApplying(false);
        console.log(response.data);

        // localStorage.setItem("couponPrice", response.data.data.couponPrice);
        // localStorage.setItem("couponCode", response.data.data.couponCode);

        props.fetchCart();
      } else {
        setErrormessage(response.data.message);
      }
    } catch (error) {
      setIsApplying(false);
      setErrormessage(error.response.data.message);
    }
  };

  const handleApplyPoints = async () => {
    try {
      setApplyingPoints(true);
      const response = await axiosInstance.post("/api/v1/coupon/applyPoints", {
        points: parseInt(referralPoints),
        cartId: props.cartId,
        chatId: props.chatId,
        cartPrice: props.totalPrice,
      });

      if (response.data.success) {
        setApplyingPoints(false);
        props.fetchCart();
      }
    } catch (error) {}
  };

  // const couponPrice = localStorage.getItem("couponPrice");

  const handleFetchZones = async () => {
    try {
      const response = await axiosInstance.get("/api/v1/zones/getAllZones");

      if (response.data.success) {
        setZonesData(response.data.data[0]);
      }
    } catch (error) {}
  };

  useEffect(() => {
    handleFetchZones();
  }, []);

  useEffect(() => {
    if (zonesData && zonesData.shippingMethod) {
      const checkZone =
        zonesData?.shippingMethod.toLowerCase() === "free shipping";

      if (checkZone) {
        const regions = zonesData.regions.map((region) => region.toLowerCase());

        setFreeRegions(regions);
      }
    }
  }, [zonesData]);

  useEffect(() => {
    if (
      freeRegions.includes(address && address.toLowerCase()) ||
      props.couponType === "Free Shipping"
    ) {
      setPlaceOrder(true);
    }
  }, [address, freeRegions]);

  return (
    <>
      <p className="text-xl font-medium">Payment Details</p>
      <p className="text-gray-400">
        Complete your order by providing your payment details.
      </p>
      <form onSubmit={handleSubmit} className="mb-8">
        <div>
          <label
            htmlFor="card-holder"
            className="mt-4 mb-2 block text-sm font-medium"
          >
            Username
          </label>
          <div className="relative">
            <input
              type="text"
              id="card-holder"
              name="card-holder"
              readOnly
              disabled
              value={props.cartUser.userName}
              className="w-full cursor-not-allowed bg-gray-100 rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm uppercase shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="Your full name here"
            />
            <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <PiUserList className="h-5 w-5 text-gray-400" />
            </div>
          </div>
          <label
            htmlFor="card-no"
            className="mt-4 mb-2 block text-sm font-medium"
          >
            Telegram Chat Id
          </label>
          <div className="relative flex-shrink-0">
            <input
              type="text"
              id="card-no"
              name="card-no"
              readOnly
              disabled
              value={props.cartUser.chatId}
              className="w-full cursor-not-allowed bg-gray-100 rounded-md border border-gray-200 px-2 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
              placeholder="xxxx-xxxx-xxxx-xxxx"
            />
            <div className="pointer-events-none absolute inset-y-0 left-0 inline-flex items-center px-3">
              <PiUserRectangleLight className="h-5 w-5 text-gray-400" />
            </div>
          </div>
          <div className="flex justify-between w-12/12">
            <div className="relative flex-shrink-0 w-8/12">
              <label
                htmlFor="billing-address"
                className="mt-4 mb-2 block text-sm font-medium"
              >
                Shipping Address
              </label>
              <input
                ref={searchInput}
                required
                className="w-full rounded-md border border-gray-200 px-4 py-3 pl-5 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                type="text"
                name="search"
                placeholder="Enter your delivery address..."
                value={address}
                onChange={(e) => setAddress(e.target.value)}
              />
              <FontAwesomeIcon
                onClick={findMyLocation}
                size="lg"
                className="text-black relative bg-transparent z-20 ml-[-35px] cursor-pointer"
                icon="fa-location-crosshairs"
              />
            </div>
            <div className="relative flex-shrink-0 w-3/12">
              <label
                htmlFor="postal-code"
                className="mt-4 mb-2 block text-sm font-medium"
              >
                Postal Code
              </label>
              <input
                type="text"
                id="postal-code"
                required
                name="postalCode"
                onChange={onChange}
                value={credentials.postalCode}
                className="w-full rounded-md border border-gray-200 px-4 py-3 pl-5 text-sm shadow-sm outline-none focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                placeholder="Postal Code"
              />
            </div>
          </div>

          {/* Total */}
          <p className="mt-8 text-lg font-medium">Shipping Methods</p>
          {freeRegions.includes(address && address.toLowerCase()) ||
          props.couponType === "Free Shipping" ? (
            <>
              <ShippingMethods
                value="ASAP Long Distance Delivery"
                text="ASAP Long Distance Delivery"
                icon={SameDayDelivery}
                selected={false}
                address={address}
                changeCheck={changePlaceOrder}
                shortDescription="Your items will get to you today"
              />

              <ShippingMethods
                value="Long Distance Delivery"
                text="Long Distance Delivery"
                icon={SameDayDelivery}
                selected={false}
                changeCheck={changePlaceOrder}
                shortDescription="Your items will get to you in the next 48 hours"
              />

              <ShippingMethods
                value="Free Shipping"
                text="Free Shipping"
                changeCheck={changePlaceOrder}
                icon={SameDayDelivery}
                selected={true}
                shortDescription="Free shipping because your address is in the free zone."
              />
            </>
          ) : (
            <>
              <ShippingMethods
                value="ASAP Long Distance Delivery"
                text="ASAP Long Distance Delivery"
                icon={SameDayDelivery}
                changeCheck={changeCheck}
                selected={
                  selected === "ASAP Long Distance Delivery" ? true : false
                }
                address={address}
                shortDescription="Your items will get to you today"
              />

              <ShippingMethods
                value="Long Distance Delivery"
                text="Long Distance Delivery"
                changeCheck={changeCheck}
                icon={SameDayDelivery}
                selected={selected === "Long Distance Delivery" ? true : false}
                shortDescription="Your items will get to you in the next 48 hours"
              />
              <ShippingMethods
                value="Free Shipping"
                text="Free Shipping"
                icon={SameDayDelivery}
                selected={false}
                changeCheck={changePlaceOrder}
                shortDescription="Free shipping because your address is in the free zone."
              />
            </>
          )}

          <p className="mt-8 text-lg font-medium">Payment Methods</p>
          <select
            onChange={handlePaymentOptions}
            required
            className="bg-gray-50 border mt-5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
          >
            <option hidden>Select Payment Option</option>
            <option>Cash on Delivery</option>
            {/* <option>Visa (Credit/Debit Cards)</option> */}
            <option>Cryptocurrency</option>
          </select>

          <section className="lg:gap-5 flex items-center justify-between flex-col lg:flex-row">
            <CouponInput
              handleChange={handleChangeCouponValue}
              isLoading={isApplying}
              couponValue={couponValue}
              handleApplyCoupon={handleApplyCoupon}
              errorMessage={errormessage}
              couponCode={props.couponCode}
              couponPrice={props.couponPrice}
            />
            <PointsInput
              handleApplyPoints={handleApplyPoints}
              isLoading={applyingPoints}
              handleChangePointsValue={handleChangePointsValue}
              referralPoints={referralPoints}
            />
          </section>

          <div className="mt-6 border-t border-b py-2">
            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Subtotal</p>
              <p className="font-semibold text-gray-900">
                €{props.subTotalPrice}.00
              </p>
            </div>

            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Shipping</p>

              {(freeRegions.length > 0 &&
                freeRegions.includes(address.toLowerCase())) ||
              props.couponType === "Free Shipping" ? (
                <p className="font-semibold text-gray-900">Free Shipping</p>
              ) : (
                <>
                  {selected === "ASAP Long Distance Delivery" && (
                    <p className="font-semibold text-gray-900">€10.00</p>
                  )} 
                  {selected === "Long Distance Delivery" && (
                    <p className="font-semibold text-gray-900">€5.00</p>
                  )}
                  {selected !== "Long Distance Delivery" &&
                    selected !== "ASAP Long Distance Delivery" && (
                      <p className="font-semibold text-gray-900">€0.00</p>
                    )}
                </>
              )}
            </div>

            <div className="flex items-center justify-between">
              <p className="text-sm font-medium text-gray-900">Coupon</p>

              <p className=" font-semibold text-gray-900">
                {props.couponPrice ? `-€${props.couponPrice}` : "€0.00"}
              </p>
            </div>
          </div>

          <div className="mt-6 flex items-center justify-between">
            <p className="text-sm font-medium text-gray-900">Total</p>
            {selected !== "Long Distance Delivery" &&
              selected !== "ASAP Long Distance Delivery" ? (
                <p className="text-2xl font-semibold text-gray-900">
                  €{props.totalPrice}.00
                </p>
              ) : ( 
                <p className="text-2xl font-semibold text-gray-900">
                  €{props.totalPrice}.00
                </p>
              )}
          </div>
        </div>

        <button
          className="mt-4  w-full rounded-md bg-[#E9C95D] px-6 py-3 font-medium text-white"
          type="submit"
          disabled={isLoading}
        >
          {isLoading ? "Processing..." : "Pay Now"}
        </button>
        <div className="mt-6 text-center text-sm text-gray-500">
          <p>
            or{" "}
            <Link
              to="/all-products"
              className="font-medium text-[#E9C95D] hover:text-[#E9C95D]"
            >
              Continue Shopping
              <span aria-hidden="true"> &rarr;</span>
            </Link>
          </p>
        </div>
      </form>
    </>
  );
};
